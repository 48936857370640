<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
        <a-input-search v-model="searchForm.search" placeholder="单号" allowClear @search="search" />
      </a-col>

      <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="onChangePicker" style="width: 100%;" />
          </a-form-model-item>
        </a-col>
      <!--<a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-select v-model="searchForm.is_outline_order" placeholder="线上或线下" allowClear style="width: 100%;" @change="search">
            <a-select-option :value="true">线下</a-select-option>
            <a-select-option :value="false">线上</a-select-option>
          </a-select>
        </a-col>
      <a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-select v-model="searchForm.is_arrears" placeholder="收款状态" allowClear style="width: 100%;" @change="search">
            <a-select-option :value="true">有欠款</a-select-option>
            <a-select-option :value="false">完成</a-select-option>
          </a-select>
        </a-col>-->
        <div style="margin-bottom: 12px; float: right;">
          <a-button type="primary" icon="plus" style="margin: 0 8px;" @click="handelBatchOut">批量出库</a-button>
        </div>
      <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
        <a-range-picker @change="onChangePicker" />
      </a-col> -->
    </a-row>

    <a-row style="margin-top: 12px;">
      <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="tableChange">
        <div slot="action" slot-scope="value, item">
          <a-button-group size="small">
            <a-button size="small" @click="detial(item)">详情</a-button>
            <a-button type="primary" size="small" :disabled="item.is_completed || item.is_void" @click="toStockOut(item)">出库</a-button>
            <a-button size="small" :disabled="item.is_void || item.type!='sales' " @click="saleReturn(item)">退货</a-button>
          </a-button-group>
        </div>
      </a-table>
    </a-row>

  </div>
</template>

<script>
  import { stockOutOrdersList, stockOutOrderMark } from '@/api/warehouse'

  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '单号',
            dataIndex: 'number',
            sorter: true,
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
          },

          {
            title: '出库类型',
            dataIndex: 'type_display',
          },
          {
            title: '出库产品',
            customRender: (value, item, index) => {
              let products = ""
              for (let i in item.stock_out_goods_items){
                  products = products + item.stock_out_goods_items[i].goods_name + ','
              }
              return products.substring(0,20)
            },
            width: 160,
          },
          {
            title: '金额',
            dataIndex: 'sales_total_amount',
          },

          {
            title: '关联单号',
            customRender: (value, item, index) => {
              return item.purchase_return_order ? item.purchase_return_order_number : item.sales_order_number
            },
          },
          {
            title: '出库完成状态',
            dataIndex: 'is_completed',
            customRender: (value, item, index) => {
              return item.is_void ? '已作废' : item.is_completed ? '完成' : '待出库'
            },
          },
          {
            title: '处理日期',
            dataIndex: 'create_time',
            width: 170
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
        ],
        searchForm: { page: 1, is_completed: false, pageSize: 20, is_void:false },
        pagination: { current: 1, total: 0, pageSize: 20 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
        selectedRowKeys: [],
        selectedRows: []
      };

    },
    computed: {
        rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // 序号
          this.selectedRowKeys = selectedRowKeys;
          // 多选的值
          this.selectedRows = selectedRows;
        },
      };
    },
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        stockOutOrdersList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        let startDate = date[0], endDate = date[1];
        this.searchForm.start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
        this.searchForm.end_date = endDate ? endDate.add(1, 'days').format('YYYY-MM-DD') : undefined;
        this.search();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      onSelectChange(selectedRowKeys, selectedRows) {
          // 序号
          this.selectedRowKeys = selectedRowKeys;
          // 多选的值
          this.selectedRows = selectedRows;
      },
      handelBatchOut() {
          this.loading = true;
          let sr = this.selectedRows

          //console.log("this.selectedRows",this.selectedRows)
          for (let it in sr) {
              console.log(sr[it])
              if(this.selectedRows[it].is_completed || this.selectedRows[it].out_mark){
                 continue;
              }
              let params = {id: this.selectedRows[it].id, out_mark: 1}
              stockOutOrderMark(params).then(data => {
                  //this.$message.success("已批量添加到后台自动出库，大约需要3分钟左右完成");
                  this.items = data.results;
              }).finally(() => {

            });
          }
          this.$message.success("已批量添加到后台自动出库，大约需要3分钟左右完成");
          this.loading = false;
          this.list();
      },

      toStockOut(item) {
        this.$router.push({ path: '/warehouse/outStock_create', query: { id: item.id } });
      },
      detial(item) {
        this.$router.push({ path: '/warehouse/outStock_detail', query: { id: item.id } });
      },

      saleReturn(item){
        this.$router.push({ path: '/sale/sale_return_create', query: { id: item.sales_order } });
      },

      voidItem(item) {
        stockOutOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>